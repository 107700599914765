<template>
  <div>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <b-link class="brand-logo">
          <div class="d-none d-lg-flex align-items-center">
            <img fluid :src="logoA" alt="Logo" />
            <h2 class="brand-text text-primary ml-1">Agenda Bank</h2>
          </div>
        </b-link>

        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5 imagemIlustrativa">
            <b-img fluid :src="this.randomImage" alt="Imagem Ilustrativa" />
          </div>
        </b-col>

        <!-- Login-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <div class="mb-3 d-flex align-items-center justify-content-center">
              <img fluid :src="logoAgenda" alt="Logo" />
            </div>
            <b-card-title title-tag="h2" class="font-weight-bold center mb-2 d-flex justify-content-center">
              Agenda Bank
            </b-card-title>
            <b-card-text class="mb-2"> Informe abaixo suas credenciais para acesso. </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form class="auth-login-form mt-2" @submit.prevent>
                <!-- email -->
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider #default="{ errors }" name="Email" rules="required|email">
                    <b-form-input
                      id="login-email"
                      v-model="dados.email"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="joao@exemplo.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group label="Senha" label-for="login-password">
                  <validation-provider #default="{ errors }" name="Password" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input
                        id="login-password"
                        v-model="dados.password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <div class="d-flex justify-content-end mb-1">
                  <b-link :to="{ name: 'esqueci-a-senha' }">
                    <small>Esqueceu a senha?</small>
                  </b-link>
                </div>

                <b-button type="submit" variant="primary" block @click="validationForm" :disabled="loading">
                  {{ !loading ? 'Acessar' : '' }}
                  <b-spinner small v-if="loading" />
                </b-button>

                <div class="d-flex justify-content-end mt-2 mb-1">
                  <b-link variant="secondary" @click="acessarCalculadora()">
                    <h6>Faça aqui sua simulação de empréstimo</h6>
                  </b-link>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
      </b-row>
    </div>
    <p class="clearfix mb-0">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        <div>
          COPYRIGHT © {{ new Date().getFullYear() }}
          <b-link class="ml-25" href="http://web.agendaassessoria.com.br" target="_blank">Agenda Bank</b-link>
          <span class="d-none d-sm-inline-block">, Todos os Direitos Reservados</span>
        </div>
        <span class="d-none d-sm-inline-block">VERSION: {{ appVersion }}</span>
      </span>
    </p>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import useJwt from '@/auth/jwt/useJwt'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import store from '@/store/index'
  import { getHomeRouteForLoggedInUser } from '@/auth/utils'
  import { detectarNavegador } from '@/libs/utils'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        status: '',
        sideImg: require('@/assets/images/pages/login-v2.svg'),
        aImg: require('@/assets/images/logo/Alogo2.png'),
        agendaImg: require('@/assets/images/logo/logo.png'),
        sigla: '',
        required,
        dados: {},
        loading: false,
        ip: 'Indisponível', // Valor inicial
        captchaLoaded: false,

        // Imagens
        imageList: [
          'imagem1.svg',
          'imagem2.svg',
          'imagem3.svg',
          'imagem4.svg',
          'imagem5.svg',
          'imagem6.svg',
          'imagem7.svg',
          'imagem8.svg',
          'imagem9.svg',
          'imagem10.svg',
          'imagem11.svg',
          'imagem12.svg',
        ],
        randomImage: require('@/assets/images/imagensPaginaInicial/imagem1.svg'),
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      logoA() {
        return this.aImg
      },
      logoAgenda() {
        return this.agendaImg
      },
      appVersion() {
        return process.env.VUE_APP_VERSION
      },
      titulo() {
        return process.env.VUE_APP_TITULO
      },
      tituloAmbiente() {
        return (
          process.env.VUE_APP_TITULO + (process.env.VUE_APP_AMBIENTE != undefined ? ' / ' + process.env.VUE_APP_AMBIENTE : '')
        )
      },
    },
    mounted() {
      this.sigla = localStorage.getItem('sigla')
      this.fetchIP()
      this.loadCaptcha()
    },
    created() {
      this.randomImage = require(`@/assets/images/imagensPaginaInicial/${this.getRandomImage()}`)
    },
    methods: {
      async loadCaptcha() {
        if (document.getElementById('recaptcha-script') && this.captchaLoaded) return

        const script = document.createElement('script')
        script.id = 'recaptcha-script'
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LcvT_spAAAAAG9MhZiCt_M8WfAN_NMRvhR8iam0'
        script.async = true
        script.defer = true

        let _this = this

        const loadScript = new Promise((resolve, reject) => {
          script.onload = () => {
            _this.captchaLoaded = true
            resolve()
          }
          script.onerror = () => reject(new Error('Failed to load reCAPTCHA script'))
        })

        document.head.appendChild(script)

        await loadScript
        return
      },
      async getCaptchaToken() {
        await this.loadCaptcha()
        await new Promise((resolve) => window.grecaptcha.ready(resolve))
        const token = await window.grecaptcha.execute('6LcvT_spAAAAAG9MhZiCt_M8WfAN_NMRvhR8iam0', { action: 'login' })
        return token
      },
      fetchIP() {
        useJwt
          .get('https://api.ipify.org?format=json')
          .then((response) => {
            this.ip = response.data.ip // Atualiza o IP no estado do componente
            window.globalIP = response.data.ip
          })
          .catch((error) => {
            console.error('Erro ao obter o endereço IP:', error)
            this.ip = 'Erro ao obter o IP'
          })
      },
      validationForm() {
        let navegadorUtilizado = detectarNavegador()
        this.$refs.loginValidation.validate().then(async (success) => {
          if (success) {
            this.loading = true
            try {
              const token = await this.getCaptchaToken()
              const response = await useJwt.Getlogin({
                email: this.dados.email,
                password: this.dados.password,
                ip: this.ip,
                descricao: navegadorUtilizado,
                captchaToken: token,
              })
              this.loading = false
              const userData = this.$jwtDecode(response.data.userData).userData
              // if (userData.role != 'master') {
                //   if (userData.institutosVinculados.length < 1) {
                //     this.$message.error('Cadastro incorreto, contate o administrador!')
                //     return
                //   }
                //   if (userData.grupoAcessoVinculado.length < 1) {
                //     this.$message.error('Cadastro incorreto, contate o administrador!')
                //     return
                //   }
                // }

              if (!userData.ativo) {
                this.$message.error('Usuário ou senha incorretos!')
                return
              }

              if (userData.bloqueioIp && userData.bloqueioIp !== this.ip) {
                this.$message.error('Acesso não permitido, contate o administrador!')
                return
              }

              if (userData.bloqueioHorarioFim && userData.bloqueioHorarioInicio) {
                const retorno = await this.verificarHorarioBloqueio(userData);
                if (retorno) {
                  return
                }
              }

              if (userData.role !== 'master') {
                await this.carregaLogoSistema(userData.Instituto.id)
              }

              useJwt.setToken(response.data.accessToken)
              if (userData.role !== 'master') {
                await useJwt.get(`cadastro/instituto/${userData.institutoId}`).then((response) => {
                  userData.Instituto = response.data;
                }).catch((error) => {
                  console.error(error)
                })

                await useJwt.post(`grupo-acesso/GetGrupoPorId/${userData.grupoAcessoId}`).then((response) => {
                  userData.GrupoAcesso = response.data[0];
                }).catch((error) => {
                  console.error(error)
                })
              }

              localStorage.setItem('userData', JSON.stringify(response.data.userData))
              sessionStorage.removeItem('itensCarregados')

              this.$ability.update(userData.ability)

              this.$router.replace(getHomeRouteForLoggedInUser(userData.role)).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Seja Bem Vindo ${userData.name}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Você se conectou com sucesso como ${userData.role}!`,
                  },
                })
              })
            } catch (error) {
              console.error('error', error)
              this.loading = false

              if (error.response?.data?.message?.status === 408) {
                this.$message.error('Conta bloqueada! Contate o administrador para liberar o acesso!')
              } else if (error.response?.data?.message?.status === 401) {
                this.$message.error('Acesso bloqueado!')
              } else {
                this.$message.error('Usuário ou senha incorretos!')
              }
              this.loading = false
            }
         }
        })
      },
      acessarCalculadora() {
        if (!this.sigla) this.sigla = window.location.href.toLowerCase().split('login?')[1]

        if (!this.sigla) return this.$message.error('Link de acesso incorreto, favor informar a sigla do cliente na url!')
        let url = `${window.location.origin}/calculadoraEmprestimo?sigla=${this.sigla}`
        window.open(url, '_self')
      },
      async verificarHorarioBloqueio(userData) {
        const now = new Date()
        const currentHour = now.getHours()
        const currentMinute = now.getMinutes()

        // Verifica hora inicial
        if (userData.bloqueioHorarioInicio) {
          const [horaBloqueioInicio, minutoBloqueioInicio] = userData.bloqueioHorarioInicio.split(':').map(Number)

          if (currentHour < horaBloqueioInicio) {
            this.$message.error('Acesso não permitido, contate o administrador!')
            return true
          } else if (horaBloqueioInicio == currentHour && currentMinute < minutoBloqueioInicio) {
            this.$message.error('Acesso não permitido, contate o administrador!')
            return true
          }
        }

        // Verifica hora final
        if (userData.bloqueioHorarioFim) {
          const [horaBloqueioFim, minutoBloqueioFim] = userData.bloqueioHorarioFim.split(':').map(Number)

          if (currentHour > horaBloqueioFim) {
            this.$message.error('Acesso não permitido, contate o administrador!')
            return true
          } else if (horaBloqueioFim == currentHour && currentMinute > minutoBloqueioFim) {
            this.$message.error('Acesso não permitido, contate o administrador!')
            return true
          }
        }
        return false
      },
      carregaLogoSistema(institutoId) {
        if (!institutoId)
          return localStorage.setItem(
            'images',
            JSON.stringify({
              logo: null,
              logoMarca: null,
              logoRelatorio: null,
              logoCalculadora: null,
            }),
          )

        useJwt
          .get(`auth/carregarLogosSistema/${institutoId}`)
          .then((response) => {
            const logos = {
              logo: response.data.logo,
              logoMarca: response.data.logoMarca,
              logoRelatorio: response.data.logoRelatorio,
              logoCalculadora: response.data.logoCalculadora,
            }

            localStorage.setItem('images', JSON.stringify(logos))
          })
          .catch((error) => {
            console.error(error)
          })
      },
      getRandomImage() {
        const randomIndex = Math.floor(Math.random() * this.imageList.length)
        return this.imageList[randomIndex]
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';

  .imagemIlustrativa {
    justify-content: center;
  }

  .imagemIlustrativa img {
    max-width: 700px;
  }
</style>
